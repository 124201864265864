// Here you can add other styles



.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
}

.flex-column-20{
    flex: 20%;
    max-width: 20%;
}

.flex-column-25{
    flex: 25%;
    max-width: 25%;
}

.flex-column-30{
    flex: 30%;
    max-width: 30%;
}

.flex-column-50{
    flex: 50%;
    max-width: 50%;
}

.flex-column-60{
    flex: 60%;
    max-width: 60%;
}

.flex-column-70{
    flex: 70%;
    max-width: 70%;
}

.flex-column-75{
    flex: 72%;
    max-width: 72%;
}

.flex-column-100{
    flex: 100%;
    max-width: 100%;
}

.flex-column-temp-details{
    flex: 20.5%;
    max-width: 20.5%;
}

.dash-details-button{
    width: 250px;
    font-weight: bold;
}

.title-text-speedometer {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.title-text-temp {
    display: block;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    padding-left: 2%
}

.title-text-tempRooms {
    display: block;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}

.title-text-ratings{
    display: block;
    text-align: center;
    font-size: 48px;
    font-weight: bold;
}

.title-text-details{
    display: block;
    text-align: center;
    font-size: 48px;
}

.title-text-progressbar{
    display: block;
    text-align: center;
    font-size: 18px;
}

.fleet-monitor-panel{
    flex: 22%;
    max-width: 22%;
}

.fleet-monitor-panel-title{
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin: 5% 0 0 5%;
}

.fleet-monitor-panel-connected{
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #20a8d8;
}

.fleet-monitor-panel-distance{
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #ffc107;
}

.fleet-monitor-panel-alert{
    display: block;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #f86c6b;
}

.fleet-date-panel-title{
    display: block;
    text-align: left;
    font-size: 14px;
    margin: 5% 0 0 5%;
}

.fleet-date-panel-text{
    display: block;
    text-align: left;
    font-size: 14px;
    margin-left: 2%
}

.fleet-car-panel{
    flex: 32%;
    max-width: 32%;
}

.fleet-map-panel{
    flex: 67%;
    max-width: 67%;
}

.fleet-charts{
    padding: 5% 0 5% 0;
}

.fleet-charts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline; 
}

.fleet-charts-size {
    flex: 30%;
    max-width: 30%;
}

.fleet-details-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ecf0f1;
    padding: 7% 7% 7% 7%;
}

.fleet-details-title{
    flex: 45%;
    max-width: 45%;
    font-weight: 500;
}

.fleet-details-carplate{
    flex: 55%;
    max-width: 55%;
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.fleet-details-text{
    flex: 55%;
    max-width: 55%;
}

.fleet-details-btn-right{
    flex: 100%;
    text-align: right;
    padding: 0 7% 3% 0;
}

.fleet-mondtl-card{
    padding: 2% 5% 0 5%;
}

.fleet-mondtl-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.fleet-mondtl-table{
    flex: 33%;
    max-width: 33%;
}

.fleet-mondtl-panel{
    flex: 23%;
    max-width: 23%;
}

.fleet-mondtl-panel-time{
    display: block;
    font-size: 18px;
    font-weight: 500;
}

.fleet-mondtl-obd-data{
    flex: 75%;
    max-width: 75%;
}

.fleet-mondtl-map{
    flex: 100%;
    width: 100%;
    margin: 0 -3% 0 -3%;
}

.report-dodetails-table{
    flex: 45%;
    max-width: 45%;
}


@media (max-width: 1000px){
    .flex-column-25{
        flex: 100%;
        max-width: 100%;
    }
    
    .flex-column-30{
        flex: 95%;
        max-width: 95%;
    }
    
    .flex-column-50{
        flex: 45%;
        max-width: 45%;
    }
    
    .flex-column-70{
        flex: 95%;
        max-width: 95%;
    }
    
    .flex-column-75{
        flex: 95%;
        max-width: 95%;
    }
    
    .flex-column-100{
        flex: 95%;
        max-width: 95%;
    }
    
    .flex-column-temp-details{
        flex: 45%;
        max-width: 45%;
    }
    
    
    .fleet-monitor-panel{
        flex: 45%;
        max-width: 45%;
    }

    .fleet-mondtl-table{
        flex: 100%;
        max-width: 100%;
    }

    .fleet-mondtl-panel{
        flex: 100%;
        max-width: 100%;
    }

    .fleet-mondtl-obd-data{
        flex: 100%;
        max-width: 100%;
    }

    .report-dodetails-table{
        flex: 100%;
        max-width: 100%;
    }
}

.live-monitoring-maps-wrapper{
    width: 100%;
    height: auto;
    min-height: 500px;
}

.login-body {
    background-image: url('./assets/img/enermo_home.jpg') !important;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center; // background-attachment: fixed;
  }

  .automa-logo-login {
      height: auto;
      min-width: 150px;
      width: 25%;
      position: absolute;
      top: 3em;
      left: 3em;
  }

  .input-feedback {
      margin-top: -1em;
      color: red;
  }